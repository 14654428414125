<template>

</template>

<script>
import AuthService from '../auth';
import urls from "@/router/urls";

export default {
  name: 'AutoLogin',

  data() {
    return {
      loginForm: {
        data: {
          token: null,
        },
        errors: {
          data: {},
          global: [],
        },
        inProcessing: false,
      },
    };
  },

  created() {
    try {
      this.isLoggingOut = true;

      AuthService.logout();
      // console.log('store.getters.loginParams', this.$store.getters.loginParams);
      // console.log('currentRoute ---', this.$router.currentRoute);

      this.autologin()

    } catch (e) {
      //
    } finally {
      this.isLoggingOut = false;
    }
  },

  methods: {
    async autologin() {
      this.loginForm.errors.global = [];

      this.loginForm.inProcessing = true;
      try {
        const url = urls.urlInsalesAutologin + this.$store.getters.loginParams;

        await AuthService.loginByInsalesTokens(url);

        let nameRoute = 'admin.settings';
        if (localStorage.getItem('isConfigured') === 'true') {
          nameRoute = 'admin.payments.list'
        }

        await this.$router.push({ name: nameRoute });

      } catch (e) {
        if (e.response) {
          if (e.response.status === 403) {
            this.loginForm.errors.global.push('Аккаунт заблокирован');
          } else if (e.response.status === 404) {
            this.loginForm.errors.global.push('Неверный E-mail или пароль');
          }
        }
        console.log('error', e)
      } finally {
        this.loginForm.inProcessing = false;
      }
    },
  },
};
</script>
